import { url } from "../config.js";

export const loginUrl = url + "api/v1/login_auth";
export const getAllUserUrl = url + "api/v1/get_all_user";
export const getTypeMailUrl = url + "api/v1/get_type_email";
export const getUserUrl = url + "api/v1/get_user_data";
export const createMailUrl = url + "api/v1/new_mail";
export const getMailUrl = url + "api/v1/get_mail_data";
export const updateMailStatusUrl = url + "api/v1/update_mail_status";
export const updateMailContentUrl = url + "api/v1/update_mail_content";
export const refreshTokenUrl = url + "api/v1/refresh_jwt";
