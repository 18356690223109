import { atom } from "recoil";
export const institutionIDState = atom({
    key: "institutionID",
    default: 0,
});
export const divisionIDState = atom({
    key: "divisionID",
    default: 0,
});

export const roleIDState = atom({
    key: "roleID",
    default: 0,
});
