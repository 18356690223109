import { useState } from "react";

function useToken(keyItem) {
    const getToken = () => {
        const tokenString = localStorage.getItem(keyItem);
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem(keyItem, JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token,
    };
}

export default useToken;
