import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login";
import { Route, Switch } from "react-router-dom";
import useToken from "./utils/useToken";
import Navbar from "./components/Navbar";
import OutMail from "./pages/OutMail";
import InMail from "./pages/InMail";
import CreateMail from "./pages/CreateMail";
import { RecoilRoot } from "recoil";
import ViewMail from "./pages/ViewMail";

function App() {
    const { token: accessToken, setToken: setAccessToken } = useToken(
        "noni_access"
    );
    const { token: refreshToken, setToken: setRefreshToken } = useToken(
        "noni_refresh"
    );
    if (!accessToken && !refreshToken) {
        return (
            <Login
                setAccessToken={setAccessToken}
                setRefreshToken={setRefreshToken}
            />
        );
    }

    return (
        <RecoilRoot>
            <Navbar />
            <div className="container mt-3">
                <Switch>
                    <Route path="/" exact>
                        <InMail />
                    </Route>
                    <Route path="/pesan-keluar" exact>
                        <OutMail />
                    </Route>
                    <Route path="/pesan-masuk" exact>
                        <InMail />
                    </Route>
                    <Route path="/tulis-pesan" exact>
                        <CreateMail />
                    </Route>
                    <Route path="/tampil-pesan" exact>
                        <ViewMail />
                    </Route>
                </Switch>
            </div>
            <div className="jumbotron text-center" style={{ marginBottom: 0 }}>
                <p>Created By Noni</p>
            </div>
        </RecoilRoot>
    );
}

export default App;
