import React, { useEffect } from "react";
import { GoogleLogout } from "react-google-login";
import { NavLink, useHistory } from "react-router-dom";
import { clientId } from "../config";
import useToken from "../utils/useToken";
import { useRecoilState } from "recoil";
import {
    institutionIDState,
    divisionIDState,
    roleIDState,
} from "../utils/atoms";
import { getUserUrl, refreshTokenUrl } from "../utils/links.js";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

function Navbar() {
    const { token: accessToken, setToken: setAccessToken } = useToken(
        "noni_access"
    );
    const { token: refreshToken, setToken: setRefreshToken } = useToken(
        "noni_refresh"
    );
    const history = useHistory();
    const responseSuccess = () => {
        setAccessToken("");
        setRefreshToken("");
        history.push("/");
        window.location.reload(false);
    };
    const [institutionID, setInstitutionID] = useRecoilState(
        institutionIDState
    );
    const [divisionID, setDivisionID] = useRecoilState(divisionIDState);

    const [roleID, setRoleID] = useRecoilState(roleIDState);

    const getUser = () => {
        const refreshAuth = (failedRequest) =>
            axios
                .post(refreshTokenUrl, null, {
                    headers: {
                        Authorization: "Bearer " + refreshToken,
                    },
                })
                .then((tokenRefreshResponse) => {
                    failedRequest.response.config.headers["Authorization"] =
                        "Bearer " + tokenRefreshResponse.data.access_token;
                    setAccessToken(tokenRefreshResponse.data.access_token);
                    return Promise.resolve();
                });

        createAuthRefreshInterceptor(axios, refreshAuth);

        axios
            .get(getUserUrl, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(
                (res) => {
                    const {
                        division_id,
                        institution_id,
                        role_id,
                    } = res.data.data;
                    setDivisionID(division_id);
                    setInstitutionID(institution_id);
                    setRoleID(role_id);
                },
                (err) => {
                    console.log(err.response.data);
                }
            );
    };
    useEffect(() => {
        getUser();
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <NavLink className="navbar-brand" to="/pesan-masuk">
                    Noni
                </NavLink>

                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/pesan-masuk">
                                Pesan Masuk
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/pesan-keluar">
                                Pesan Keluar
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/tulis-pesan">
                                Tulis Pesan
                            </NavLink>
                        </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <input
                            className="form-control mr-sm-2"
                            type="search"
                            placeholder="Cari"
                            aria-label="Search"
                        />
                        <button
                            className="btn btn-outline-success my-2 my-sm-0"
                            type="submit"
                        >
                            Cari Pesan
                        </button>
                    </form>
                    <GoogleLogout
                        clientId={clientId}
                        buttonText="Logout"
                        onLogoutSuccess={responseSuccess}
                        render={(renderProps) => (
                            <button
                                className="btn btn-danger ml-3 my-2 my-sm-0"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                Logout
                            </button>
                        )}
                    />
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
