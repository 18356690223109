import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import { useRecoilState } from "recoil";
import {
    institutionIDState,
    divisionIDState,
    roleIDState,
} from "../utils/atoms";
import {
    getTypeMailUrl,
    getAllUserUrl,
    refreshTokenUrl,
} from "../utils/links.js";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import useToken from "../utils/useToken";
import { Multiselect } from "multiselect-react-dropdown";

function ViewMail() {
    const { token: accessToken, setToken: setAccessToken } = useToken(
        "noni_access"
    );
    const { token: refreshToken, setToken: setRefreshToken } = useToken(
        "noni_refresh"
    );

    const [institutionID, setInstitutionID] = useRecoilState(
        institutionIDState
    );
    const [divisionID, setDivisionID] = useRecoilState(divisionIDState);

    const [roleID, setRoleID] = useRecoilState(roleIDState);

    const [typeMail, setTypeMail] = useState([]);
    const [allUser, setAllUser] = useState([]);

    const location = useLocation();
    const [data, setData] = useState(location.state);

    const handleEditorChange = (content, editor) => {
        setData({ ...data, contain: content });
    };

    const handleInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const getAllUser = () => {
        const refreshAuth = (failedRequest) =>
            axios
                .post(refreshTokenUrl, null, {
                    headers: {
                        Authorization: "Bearer " + refreshToken,
                    },
                })
                .then((tokenRefreshResponse) => {
                    failedRequest.response.config.headers["Authorization"] =
                        "Bearer " + tokenRefreshResponse.data.access_token;
                    setAccessToken(tokenRefreshResponse.data.access_token);
                    return Promise.resolve();
                });

        createAuthRefreshInterceptor(axios, refreshAuth);

        axios
            .get(getAllUserUrl, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(
                (res) => {
                    setAllUser(res.data.data);
                },
                (err) => {
                    console.log(err.response.data);
                }
            );
    };

    const getTypeEmail = () => {
        const refreshAuth = (failedRequest) =>
            axios
                .post(refreshTokenUrl, null, {
                    headers: {
                        Authorization: "Bearer " + refreshToken,
                    },
                })
                .then((tokenRefreshResponse) => {
                    failedRequest.response.config.headers["Authorization"] =
                        "Bearer " + tokenRefreshResponse.data.access_token;
                    setAccessToken(tokenRefreshResponse.data.access_token);
                    return Promise.resolve();
                });

        createAuthRefreshInterceptor(axios, refreshAuth);

        axios
            .get(getTypeMailUrl, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(
                (res) => {
                    setTypeMail(res.data.data);
                    console.log(data);
                },
                (err) => {
                    console.log(err.response.data);
                }
            );
    };

    const onSelect = (selectedList, selectedItem) => {
        setData({
            ...data,
            forward: [...data.forward, { user_id: selectedItem.id }],
        });
    };

    const onRemove = (selectedList, removedItem) => {
        let filtered = data.forward.filter((value) => {
            return value.user_id !== removedItem.id;
        });
        setData({
            ...data,
            forward: [...filtered],
        });
    };

    useEffect(() => {
        getAllUser();
        getTypeEmail();
    }, []);

    return (
        <div className="row">
            <h2 className="col-lg-12 mb-4">Tampil Pesan</h2>
            <form className="col-lg-12" onSubmit="">
                <div className="row">
                    <div className="form-group col-lg-6">
                        <label htmlFor="nomor">Nomor</label>
                        <input
                            type="text"
                            className="form-control"
                            id="nomor"
                            placeholder="Nomor"
                            name="number"
                            value={data.number}
                            onChange={handleInput}
                            required
                        />
                    </div>
                    <div className="form-group col-lg-6">
                        <label htmlFor="perihal">Perihal</label>
                        <input
                            type="text"
                            className="form-control"
                            id="perihal"
                            placeholder="Perihal"
                            name="subject"
                            value={data.subject}
                            onChange={handleInput}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <label htmlFor="tipe">Kepada</label>
                        <select
                            className="form-control"
                            id="to"
                            required
                            name="to"
                            value={data.to}
                            onChange={handleInput}
                        >
                            <option value="" disabled>
                                Pilih Tujuan
                            </option>
                            {allUser.map((user) => {
                                return (
                                    <option key={user.nik} value={user.nik}>
                                        {user.name.charAt(0).toUpperCase() +
                                            user.name.slice(1)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="form-group col-lg-6">
                        <label htmlFor="tipe">Tipe</label>
                        <select
                            className="form-control"
                            id="tipe"
                            required
                            name="id_type_mail"
                            value={data.id_type_mail}
                            onChange={handleInput}
                        >
                            <option value="" disabled>
                                Pilih Tipe Surat
                            </option>
                            {typeMail.map((type) => {
                                return (
                                    <option key={type.id} value={type.id}>
                                        {type.name.charAt(0).toUpperCase() +
                                            type.name.slice(1)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="row mx-auto mb-2">
                    <label htmlFor="tipe">Isi Surat</label>
                    <Editor
                        apiKey="xd8n9r7q9zq4kloulyphutia3clqlnxu0oq5dcjzkky2g769"
                        initialValue={data.contain}
                        init={{
                            height: "200px",
                            width: "100%",
                            menubar: false,
                            plugins:
                                "image export print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                            toolbar:
                                "export |undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </div>
                <div className="row">
                    <div className="form-group col-lg-12">
                        <label htmlFor="nomor">Tembusan</label>
                        <Multiselect
                            options={allUser.map((data) => ({
                                name: data.name,
                                id: data.nik,
                            }))} // Options to display in the dropdown
                            selectedValues={""}
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />
                    </div>
                </div>
                <div className="text-right">
                    {roleID === 1 && (
                        <button
                            type="submit"
                            className="btn btn-danger my-4 mr-2"
                        >
                            Kirim
                        </button>
                    )}
                    <button type="submit" className="btn btn-primary my-4">
                        Perbaharui
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ViewMail;
