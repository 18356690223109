import axios from "axios";
import React from "react";
import { GoogleLogin } from "react-google-login";
import { clientId } from "../config";
import { loginUrl } from "../utils/links.js";

function Login({ setAccessToken, setRefreshToken }) {
    const responseFail = (response) => {
        console.log(response);
    };

    const responseSuccess = (response) => {
        console.log(response.tokenObj.access_token);
        axios
            .post(loginUrl, {
                token_google: response.tokenObj.access_token,
            })
            .then(
                (res) => {
                    const { access_token, refresh_token } = res.data.data.token;
                    setAccessToken(access_token);
                    setRefreshToken(refresh_token);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
                    <div className="card card-signin my-5 text-center">
                        <div className="card-body">
                            <div className="">
                                <img
                                    src="https://stimlog.ac.id/wp-content/uploads/2019/02/YPBPI.gif"
                                    alt=""
                                    width="200"
                                />
                            </div>

                            <h4 className="card-title text-center mb-4">
                                Login
                            </h4>
                            <p>Bagi Anda yang sudah terdaftar, silakan login</p>
                            <div className="text-center my-4">
                                <GoogleLogin
                                    clientId={clientId}
                                    buttonText="Log in with Google"
                                    onSuccess={responseSuccess}
                                    onFailure={responseFail}
                                    cookiePolicy={"single_host_origin"}
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="card-footer text-muted">
                            <span style={{ fontSize: 12 }}>
                                Akses Anda ditolak? Silahkan konfirmasi ke admin
                                <br />
                                apabila email Anda tidak terdaftar
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
