import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import { institutionIDState, divisionIDState } from "../utils/atoms";
import { getMailUrl, refreshTokenUrl } from "../utils/links.js";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import useToken from "../utils/useToken";
import { Link, NavLink } from "react-router-dom";

function OutMail() {
    const [institutionID] = useRecoilState(institutionIDState);
    const [divisionID] = useRecoilState(divisionIDState);
    const { token: accessToken, setToken: setAccessToken } = useToken(
        "noni_access"
    );
    const { token: refreshToken } = useToken("noni_refresh");
    const [listMail, setListMail] = useState([]);

    const getOutMail = () => {
        const refreshAuth = (failedRequest) =>
            axios
                .post(refreshTokenUrl, null, {
                    headers: {
                        Authorization: "Bearer " + refreshToken,
                    },
                })
                .then((tokenRefreshResponse) => {
                    failedRequest.response.config.headers["Authorization"] =
                        "Bearer " + tokenRefreshResponse.data.access_token;
                    setAccessToken(tokenRefreshResponse.data.access_token);
                    return Promise.resolve();
                });

        createAuthRefreshInterceptor(axios, refreshAuth);

        axios
            .get(getMailUrl, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(
                (res) => {
                    setListMail(res.data.data.forward);
                },
                (err) => {
                    console.log(err.response.data);
                }
            );
    };

    useEffect(() => {
        getOutMail();
    }, []);
    return (
        <div className="row">
            <h2>Surat Keluar</h2>
            <div className="table-responsive mt-4">
                <table className="table">
                    <caption>Kumpulan surat keluar</caption>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nomor</th>
                            <th scope="col">Perihal</th>
                            <th scope="col">Kepada</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listMail.map((data, index) => {
                            return (
                                <tr key={data.id_mail}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{data.number}</td>
                                    <td>{data.subject}</td>
                                    <td>{data.to}</td>
                                    <td>{data.created_at}</td>
                                    <td>
                                        <NavLink
                                            className="btn btn-primary"
                                            to={{
                                                pathname: "/tampil-pesan",
                                                state: data,
                                            }}
                                        >
                                            View
                                        </NavLink>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OutMail;
